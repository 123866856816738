import React, { FC } from 'react';
import styles from './Loading.module.css';

const Square: FC<{
  color?: string;
  size?: number;
  classes?: string;
}> = React.memo(({ color = '#7f58af', size = 80, classes = '' }) => {
  return (
    <div className={`${styles.Square} ${classes}`} style={{ width: size, height: size, backgroundColor: color }}></div>
  );
});

export default Square;