import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useUserAuth } from "./contexts/userAuthContext";


const ProtectedRoute = ({component: Component, ...rest } : {component: any}) => {
    const { user } = useUserAuth();
    /* ToDo: loader */

    return (
        <Route
            render={props =>
                user ? <Component {...props} /> : <Redirect to="/login" />
            }
            {...rest}
        />
    );
};

ProtectedRoute.propTypes = {
    component: PropTypes.any
};

export default ProtectedRoute;
