import React, { useState, useContext, createContext } from 'react';
import axios from 'axios';
import { API_URL } from '../config/restAPI';
import { initNotification, deactivateNotification } from '../scripts/notification';

type User = {
    auth: {
        token: string;
    };
    id: number;
};

type UserAuthContext = {
    user: User | null;
    signin: (email: string, password: string) => any;
    signout: () => void;
};

export const UserAuthContext = createContext<UserAuthContext>({
    user: null,
    signin: (email: string, password: string) => { },
    signout: () => { }
});

const { Provider } = UserAuthContext;

const UserAuthProvider: React.FC<{ children: React.ReactNode }> = ({
    children
}) => {
    const auth: UserAuthContext = useProvideUserAuth();
    return <Provider value={auth}>{children}</Provider>;
};

export const useUserAuth = () => {
    return useContext(UserAuthContext);
};

const getUser = () => {
    const localUser = sessionStorage.getItem('account');
    return localUser ? JSON.parse(localUser) : null;
};

function useProvideUserAuth() {
    const [user, setUser] = useState<User | null>(getUser());

    const signin = async (email: string, password: string) => {

        return await axios
            .post(
                API_URL + '/auth/local',
                `identifier=${email}&password=${password}`,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }
            )
            .then(response => {
                if (response.data) {
                    const account = {
                        auth: {
                            token: response.data['jwt']
                        },
                        id: response.data['user']['id']
                    };
                    setUser(account);
                    sessionStorage.setItem('account', JSON.stringify(account));
                    initNotification(account);
                    return Promise.resolve();
                }
            })
            .catch(error => {
                return Promise.reject(error.response.status === 400 ? 'E-mail/Nutzername oder Passwort ist falsch' : error.response.statusText);
            });
    };

    const signout = () => {
        deactivateNotification(user);
        setUser(null);
        sessionStorage.removeItem('account');
    };

    return {
        user,
        signin,
        signout
    };
}

export default UserAuthProvider;
