import { API_URL } from '../config/restAPI';
import axios from 'axios';

const publicVapidKey = 'BPVvXgw14aMUmhbDv5gJsze9xh9MbitzS1NzE0UY4qQEth725iTzWPaWVBl0xhqbFoNjMkIEHxlR05u1ndglJYg';

export const initNotification =  (user) => {
  if ('Notification' in window && 'serviceWorker' in navigator) {
    askForPermission(user);
  } else {
    console.info("This browser doesn't support the API's required to use Push Notifications");
  }
};

const askForPermission = (user) => {
  Notification.requestPermission((result) => {
    if (result !== "granted") {
      console.info("No notification permission granted!");
    } else {
      configurePushSubscription(user);
    }
  });
};

const configurePushSubscription = (user) => {
  if (!("serviceWorker" in navigator)) {
    return;
  }
  let reg;

  navigator.serviceWorker.ready
    .then(swreg => {
      reg = swreg;
      return reg.pushManager.getSubscription();
    })
    .then((sub) => {
      if (sub === null && user) {
        const convertedVapidPublicKey = urlBase64ToUint8Array(publicVapidKey);
        reg.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidPublicKey
          })
          .then(subscription => {
            return axios(API_URL + '/subscribe', {
              method: 'POST',
              params: {},
              data: JSON.stringify(subscription),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.auth.token}`
              }
            })
          })
      } else {
        if (!user) return;
        axios(API_URL + '/activatePush', {
          method: 'POST',
          params: {},
          data: JSON.stringify(sub),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.auth.token}`
          }
        })

      }
    })
    .catch(error => {
      console.error(error);
    })
}

export const urlBase64ToUint8Array = base64String => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const deactivateNotification = async (user) => {
  if (!("serviceWorker" in navigator)) {
    return;
  }
  let reg;

  navigator.serviceWorker.ready
    .then(swreg => {
      reg = swreg;
      return reg.pushManager.getSubscription();
    })
    .then((sub) => {
      if (sub === null) {
        return
      } else {
        axios(API_URL + '/deactivatePush', {
          method: 'POST',
          params: {},
          data: JSON.stringify(sub),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.auth.token}`
          }
        })
      }
    })
    .catch(error => {
      console.error(error);
    })
}