import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loading from './components/Loading/Loading';
import UserAuthProvider from "./contexts/userAuthContext";
import ProtectedRoute from "./protectedRoute";
const Main = lazy(() => import('./layouts/Main'));
const Login = lazy(() => import('./views/Login/Login'));
const ResetPassword = lazy(() => import('./views/ResetPassword/ResetPassword'));

function App() {
    return (
        <Suspense fallback={<Loading />}>
            <Router>
                <UserAuthProvider>
                    <Switch>
                        <Route path={'/login'} component={Login} />
                        <Route path={'/resetpassword'} component={ResetPassword} />
                        <ProtectedRoute component={Main} />
                    </Switch>
                </UserAuthProvider>
            </Router>
        </Suspense>
    );
}

export default App;