import React, { FC } from 'react';
import styles from './Loading.module.css';
import Loader from './Loader';

const Loading: FC<{
  color?: string;
  size?: number;
  classes?: string;
}> = React.memo(({ color = '#009DE0', size = 40, classes = '' }) => {
  return (
    <div className={`${styles.Loader} ${classes}`}>
      <Loader color={color} size={size} />
    </div>
  );
});

export default Loading;